import { GuestsWithAgeGroup } from "./guestsWithAgeGroup";

export class GetPackageSlotsRequest {
  venueId: string;
  date: string;
  packageId: string;
  guests: number;
  guestsWithAgeGroups?: GuestsWithAgeGroup[];
  packagesTag?: string;
  duration?: number;
  lanes?: number;
  skipReservationId?: string;
  targetPackage?: string
}

export class GetActivitySlotsRequest {
  venueId: string;
  date: string;
  packageId: string;
  guests: number;
  guestsWithAgeGroups?: GuestsWithAgeGroup[];
  packagesTag?: string;
  duration: number;
  lanes: number;
  skipReservationId?: string;
  targetPackage?: string;
  selectedPackageSlot?: SelectedPackageSlotDto;
  selectedReservationActivities: ReservationActivitySlotDto[]
}


export class SelectedPackageSlotDto {
  startTime: number;
  duration: number;
  activitiesTimeWindow: number;
  packageId: string;
}

export class ReservationActivitySlotDto {
  startTime: number;
  duration: number;
}