import dayjs from "dayjs";
import { Venue, Activity, CurrencyType, Pricing } from "../store/types";
import { getVenueDurations, getPackageDurations, formatPriceForTimeSlot, slotToTimeWithDuration, slotToTime } from "../../../common/utils/formats";


export const composeActivityTimeSlots = ({
    date,
    venue,
    duration,
    isShowReservationTime,
    isHideDuration,
    guests,
    currentActivity,
    isAdmin,
    currency,
    twelveHourClockFormat,
}: {
    date: string,
    venue?: Venue,
    duration?: number,
    isShowReservationTime?: boolean,
    isHideDuration?: boolean,
    guests?: number,
    currentActivity?: Activity,
    isAdmin?: boolean,
    currency: CurrencyType,
    twelveHourClockFormat: boolean,
}) => {
    if (!venue) {
        return [];
    }
    if (!duration || (!getVenueDurations(venue, !!isAdmin).includes(duration) &&
        !isHideDuration) &&
        ((!(currentActivity?.enableDurationChoice || (isAdmin && currentActivity?.durationInSlotsForAdmin)) && !currentActivity?.duration) || (!!(currentActivity?.enableDurationChoice || (isAdmin && currentActivity?.durationInSlotsForAdmin)) && !getPackageDurations(currentActivity, isAdmin).includes(duration))) &&
        (!currentActivity?.isTimeSlotsInPackage || venue?.timeSlots.length === 0) &&
        !venue.enableAssignDuration && !currentActivity?.enableAssignDuration) {
        return [];
    }
    const timeSlotShifting = +venue.timeSlotShifting[dayjs(date).day()] || 0
    const priceLabel = !currentActivity
        ? venue?.pricing === Pricing.perPerson
            ? "/PP/HR"
            : venue?.pricing === Pricing.perPersonFlat
                ? "/PP"
                : (venue?.pricing === Pricing.flatRate || venue?.pricing === Pricing.flatRatePerLane)
                    ? ""
                    : "/HOUR"
        : currentActivity?.method === Pricing.perPerson
            ? "/PP/HR"
            : currentActivity?.method === Pricing.perPersonFlat
                ? "/PP"
                : (currentActivity?.method === Pricing.flatRate || currentActivity?.method === Pricing.flatRatePerLane)
                    ? ""
                    : "/HOUR"
    const guestSplit = venue?.guestSplit;
    let lanes = !currentActivity
        ? guests && guests <= guestSplit ? 1 : 2
        : currentActivity.numberOfLanes;
    if (currentActivity && !!currentActivity.countLanesByGuest && guests && currentActivity.maxGuestsPerLane) {
        lanes = Math.ceil(guests / currentActivity.maxGuestsPerLane);
        if (isAdmin && currentActivity.maxGuestsPerLaneForAdmin) {
            lanes = Math.ceil(guests / currentActivity.maxGuestsPerLaneForAdmin);
        }
    }
    const priceMultiplier = !currentActivity
        ? venue?.pricing === Pricing.flatRatePerLane ? lanes : 1
        : currentActivity?.method === Pricing.flatRatePerLane ? lanes : 1
    let selectedDuration = duration;
    if (!!currentActivity && !currentActivity?.enableDurationChoice && !(isAdmin && currentActivity?.durationInSlotsForAdmin)) {
        selectedDuration = currentActivity?.duration;
    }
    if (!currentActivity?.timeSlots || currentActivity?.timeSlots.length === 0) {
        return [];
    }
    let slots = currentActivity.timeSlots.map((item) => {
        const price = `${formatPriceForTimeSlot(+item.rate * priceMultiplier, currency)}${priceLabel}`;
        return ({
            text: isShowReservationTime ?
                slotToTimeWithDuration(item.time, venue.timeSlotDuration, selectedDuration, twelveHourClockFormat, timeSlotShifting) :
                slotToTime(item.time, venue.timeSlotDuration, twelveHourClockFormat, timeSlotShifting),
            key: item.time,
            price,
            time: isShowReservationTime ?
                slotToTimeWithDuration(item.time, venue.timeSlotDuration, selectedDuration, twelveHourClockFormat, timeSlotShifting) :
                slotToTime(item.time, venue.timeSlotDuration, twelveHourClockFormat, timeSlotShifting),
            slot: item.time,
            ageGroupPricing: item.ageGroupPricing


        })
    });
    if (venue.isStartTimeOnly && !isAdmin) {
        slots = slots?.filter(slot => venue.filterTimeSlots.includes(slot.slot));
    }

    return slots;
};