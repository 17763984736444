import { Box, Button, Tab, Tabs, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { getHash, getSearch, goBack, replace } from "connected-react-router";
import { chunk, get, maxBy } from "lodash";
import {
    IDropdownOption,
    Spinner,
    SpinnerSize
} from "office-ui-fabric-react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import { InfoAlert } from "../../../../common/components/Alert/Alert";
import HTMLRenderer from "../../../../common/components/HTMLRenderer/HTMLRenderer";
import {
    composeTimeSlots,
    getPackageDurations
} from "../../../../common/utils/formats";
import CalendarIcon from '../../../assets/calendar.svgr';
import defaultImage from '../../../assets/default-image.jpg';
import {
    backFromAccountAction,
    hideCancellationAction,
    resetReservationAction,
    setReservationErrorAction,
    startBookingAction,
    updateReservationAction
} from "../../actions/reservation-actions";
import {
    changeVenuePackageAction,
    changeVenuePackageWithReservationAction,
    parseHash,
    setCurrentPackageAction,
    toCustomTabNewDesignAction,
    toPartyWithVenueAction,
    toReservationWithVenueAction
} from "../../actions/venue-actions";
import { useViewport } from "../../hooks/responsive";
import useSettingColors from "../../hooks/useSettingColors";
import {
    selectIsRequestInProgress,
    selectIsUpdateReservation,
    selectRequestError,
    selectReservation,
} from "../../reducers/reservation";
import { selectUIConfig } from "../../reducers/ui-reducer";
import {
    selectAvailability,
    selectCurrentPackage,
    selectIsLoadingAvailability,
    selectIsLoadingDateOrVenue,
    selectIsLoadingPackageList,
    selectIsLoadingVenue,
    selectIsLoadingVenues,
    selectVenue,
    selectVenues
} from "../../reducers/venues";
import { CurrencyType, PackageName, Pricing, State } from "../../store/types";
import { parseUrlQuery, toUrlHash } from "../../utils/urlSearchQuery";
import { getVenueImages } from "../../utils/venue";
import GalleryModal from "../MakeReservation/GalleryModal";
import MakeReservationSummary from "../MakeReservation/MakeReservationSummary";
import PlanningEvent from "../MakeReservation/PlanningEvent";
import NoAvailiableGuests from "../PackageCard/NoAvailiableGuests";
import PackageSkeletons from "../PackageCard/PackageSkeletons";
import PackageCard from "../PackageCard/packageCard";
import { Calendar } from "../common/Calendar/Calendar";
import Footer from "../common/Footer/Footer";
import Header from "../common/Header/Header";
import SelectWithIcon from "../common/SelectWithIcon/SelectWithIcon";
import AboutVenue from "./AboutVenue";
import FilterButtons from "./FIlterButtons";
import ThingsToKnow from "./ThingsToKnow";
import VenueClosed from "./VenueClosed";
import "./makePackageReservation.scss";
import { convertFaqsToArray, formatDate, getAgeGroupsMinGuests, getGuestsWithAgeGroups } from "./utils";
import { authWithReservationTokenAction } from "../../actions/auth-actions";
import { selectAuthenticated } from "../../reducers/auth-reducer";
import { AgeGroupPricing } from "../../../../server/src/utils/ageGroups";

export interface TimeButtonType {
    text: string,
    key: number,
    price: string,
    time: string,
    slot: number,
    ageGroupPricing?: AgeGroupPricing,
}

const mapDispatchToProps = {
    changeVenuePackage: changeVenuePackageAction,
    resetReservation: resetReservationAction,
    updateReservation: updateReservationAction,
    startBooking: startBookingAction,
    toPartyWithVenue: toPartyWithVenueAction,
    setReservationError: setReservationErrorAction,
    toReservationWithVenue: toReservationWithVenueAction,
    setCurrentPackage: setCurrentPackageAction,
    goBack,
    replace,
    changeVenuePackageWithReservation: changeVenuePackageWithReservationAction,
    toCustomTab: toCustomTabNewDesignAction,
    authWithReservationToken: authWithReservationTokenAction,
    backFromAccount: backFromAccountAction,
    hideCancellation: hideCancellationAction
};

const mapStateToProps = (state: State) => ({
    isLoadingVenues: selectIsLoadingVenues(state),
    isLoadingVenueOrDate: selectIsLoadingDateOrVenue(state),
    isLoadingPackageList: selectIsLoadingPackageList(state),
    isRequestInProgress: selectIsRequestInProgress(state),
    isLoadingVenue: selectIsLoadingVenue(state),
    isLoadingAvailability: selectIsLoadingAvailability(state),
    activeVenue: selectVenue(state),
    venues: selectVenues(state),
    availability: selectAvailability(state),
    reservation: selectReservation(state),
    searchParams: getSearch(state),
    requestError: selectRequestError(state),
    uiConfig: selectUIConfig(state),
    currentPackage: selectCurrentPackage(state),
    isUpdateReservation: selectIsUpdateReservation(state),
    hashParams: getHash(state),
    authenticated: selectAuthenticated(state)
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

export const getMenuPackagePropsGuests: (
    count: number,
    selectedPackages?: PackageName[]
) => IDropdownOption[] = (
    count: number,
    selectedPackages?: PackageName[]
) => {
        const MORE_THAN_11 = 100;

        const max = maxBy(
            selectedPackages,
            (selectedPackage) => selectedPackage.maxGuests
        )?.maxGuests;
        const maxGuests = max && max > count ? max : count;
        return Array.apply(null, Array(maxGuests + 1)).map((_, index) => ({
            key: index === maxGuests ? MORE_THAN_11 : index + 1,
            text:
                index === 0
                    ? `${index + 1} Guest`
                    : `${index + 1}${index === maxGuests ? "+" : ""} Guests`,
        }));
    };

const MakePackageReservation = ({
    venues,
    activeVenue,
    isLoadingVenues,
    isLoadingVenue,
    isLoadingAvailability,
    availability,
    uiConfig,
    goBack,
    changeVenuePackage,
    startBooking,
    resetReservation,
    updateReservation,
    toPartyWithVenue,
    reservation,
    searchParams,
    hashParams,
    setReservationError,
    requestError,
    currentPackage,
    setCurrentPackage,
    changeVenuePackageWithReservation,
    isUpdateReservation,
    toCustomTab,
    replace,
    isLoadingVenueOrDate,
    isLoadingPackageList,
    authenticated,
    authWithReservationToken,
    backFromAccount,
    hideCancellation
}: Props) => {
    const [isGalleryOpen, setIsGalleryOpen] = useState(false);
    const isVenueClosed = activeVenue?.closed;



    const venuePolicies = activeVenue?.venueInfo ? [{
        heading: 'Venue Policies',
        details: activeVenue?.venueInfo || ''

    }] : []

    const top = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (top?.current) {
            window.scrollTo(0, top.current.offsetTop);
        }
    }, [top]);

    // auth with token from cancel email v2
    useEffect(() => {
        const { token, id } = parseUrlQuery(searchParams);
        if (!authenticated && token) {
            authWithReservationToken(id, token);
        }
    }, [authenticated]);

    const [tabValue, setTabValue] = React.useState(0);

    const handleChangeTabValue = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
        if (newValue === 1) {
            returnToParty()
        }
        if (newValue === 2) {
            returnToCustom()
        }
    };
    const clearPackage = () => {
        setSelectedPackageId(null);
        setCurrentPackage(undefined);
    }

    const init = useCallback(() => {
        //to handle back action
        if (packages.length > 0) {
            clearPackage()
            return
        }

        if (activeVenue) {
            //todo: refactor it, update url other way
            const { venueId, date, guests } = reservation;
            const guestsWithAgeGroups = getGuestsWithAgeGroups(currentPackage);
            if (currentPackage && currentPackage.enableDurationChoice) {
                const parsedDuration = getPackageDurations(currentPackage)[0];
                updateReservation({ duration: parsedDuration });
                if (!isLoadingVenue && venues.length > 0) {
                    changeVenuePackage({
                        updatePackage: currentPackage,
                        venueId,
                        date,
                        guests,
                        selectDuration: parsedDuration,
                        changeDateOrVenue: true,
                        guestsWithAgeGroups
                    });
                }
            } else {
                initPackageTag(activeVenue.id);
                if (!isLoadingVenue && venues.length > 0) {
                    changeVenuePackage({
                        updatePackage: currentPackage,
                        venueId,
                        date,
                        guests,
                        changeDateOrVenue: true,
                        guestsWithAgeGroups
                    });
                }
            }
        }
        console.log("loading...", venueId);
    }, [resetReservation, searchParams, hashParams]);


    const selectPackageTag = (tag: string) => {
        if (tag === 'All') tag = ''
        const newHash = toUrlHash(tag);
        const guestsWithAgeGroups = getGuestsWithAgeGroups(currentPackage)
        replace({ search: searchParams, hash: newHash });

        setSelectedPackageTag(tag || 'All')

        changeVenuePackage({
            updatePackage: currentPackage,
            venueId: venueId || activeVenue?.id as string,
            date,
            guests,
            changePackageList: true,
            guestsWithAgeGroups
        });
    };

    const initPackageTag = (venueId: string) => {
        const venue = venues?.find((venue) => venue.id == venueId);
        let { packagesTag } = parseHash(hashParams);
        let { currentPackage } = parseUrlQuery(searchParams);
        if (!venue) {
            return
        }
        if (hashParams) {
            replace({ search: searchParams, hash: hashParams });
        } else {
            replace({ search: searchParams });
        }

        if (currentPackage) {
            setSelectedPackageTag('All')
            return
        }

        if (packagesTag === 'featured') return
        if (
            venue.packageTags?.length > 0 &&
            uiConfig?.hideAllPackageTab && !packagesTag
        ) {
            const firstPackageTag = venue.packageTags[0];
            const newHash = toUrlHash(firstPackageTag);
            replace({ search: searchParams, hash: newHash });
            setSelectedPackageTag(firstPackageTag);
        }
        else if (packagesTag) setSelectedPackageTag(packagesTag)

    }

    useEffect(() => {
        if (activeVenue) {
            initPackageTag(activeVenue?.id);
        }
    }, [activeVenue?.id, venues]);

    useEffect(() => {
        setReservationError("");
        if (!(reservation.id && isUpdateReservation)) {
            init();
        } else {
            changeVenuePackageWithReservation({ changeDateOrVenue: true });
        }
    }, [isUpdateReservation]);


    const isHideDuration =
        uiConfig?.hideDuration || currentPackage?.hidePackageDuration;
    const isShowReservationTime = uiConfig?.showReservationTime;
    const currency = uiConfig?.currency || CurrencyType.USD;
    const twelveHourClockFormat = uiConfig?.twelveHourClockFormat || false;
    const { bodyColor } = useSettingColors()

    const { isMobile, isMiddleScreen, isTabletScreen } = useViewport();
    const [isCalendar, setIsCalendar] = useState(false);
    const [errorGuestCount, setErrorGuestCount] = useState<string>("");
    const [isLessTreeTimeSlots, setIsLessTreeTimeSlots] =
        useState<boolean>(false);
    const [isInitialCurrentPackageLoad, setIsInitialCurrentPackageLoad] = useState<boolean>(false);

    const closed = activeVenue?.closed;
    const { venueId, guests, slots, duration, date } = reservation;

    const timeButtons =
        closed || !currentPackage || activeVenue?.packages.length === 0
            ? []
            : composeTimeSlots({
                date,
                venue: activeVenue,
                duration,
                isShowReservationTime,
                isHideDuration,
                guests,
                currentPackage,
                currency,
                twelveHourClockFormat,
            });
    const getTimeButtonsGroup = (timeButtons: TimeButtonType[], isRanges?: boolean) => {
        if (timeButtons.length <= 2) {
            if (isLessTreeTimeSlots === false) {
                setIsLessTreeTimeSlots(true);
            }
            // Return two separate chunks for 2 time slots, so they appear in separate rows on mobile
            return isMobile ? chunk(timeButtons, 2) : chunk(timeButtons, 1);
        } else {
            if (isLessTreeTimeSlots === true) {
                setIsLessTreeTimeSlots(false);
            }
            // Adjust chunk size based on screen size and reservation time visibility
            return chunk(
                timeButtons,
                (isShowReservationTime || isMobile) ? 3 : 4
            );
        }
    };

    const timeButtonsGroups = getTimeButtonsGroup(timeButtons, isShowReservationTime);

    const selectDate = (date: string) => {
        setReservationError("");

        if (isCalendar) {
            setIsCalendar(false);
        }
        setDay(date);
    };
    const setDay = (date: string) => {
        setReservationError("");

        if (isUpdateReservation) {
            updateReservation({ slots: undefined });
            changeVenuePackageWithReservation({
                updatePackage: currentPackage,
                date,
                changeDateOrVenue: true
            });

        } else {
            changeVenuePackage({
                updatePackage: currentPackage,
                venueId,
                date,
                guests,
                changeDateOrVenue: true,
                guestsWithAgeGroups: getGuestsWithAgeGroups(currentPackage)
            });
        }


    };

    const selectVenueOption = (newVenueId?: number | string) => {
        setReservationError("");

        const newVenue = venues.find((venue) => venue.id == newVenueId);
        if (newVenueId && newVenue) {
            const recountedGuests = newVenue.maxGuests >= guests ? guests : 1;
            if (!!packagesTag) {
                replace({ search: searchParams, hash: hashParams });
            }
            if (isUpdateReservation) {
                updateReservation({ slots: undefined, packageId: undefined });
                changeVenuePackageWithReservation({
                    venueId: newVenueId.toString(),
                    date,
                    guests: recountedGuests,
                    updatePackage: undefined,
                    changeDateOrVenue: true,
                });
            } else {
                changeVenuePackage({
                    updatePackage: undefined,
                    venueId: newVenueId.toString(),
                    date,
                    guests: recountedGuests,
                    changeDateOrVenue: true,
                    changeVenueWithoutPackage: true,
                    guestsWithAgeGroups: getGuestsWithAgeGroups(currentPackage)
                });
            }
            setIsCalendar(false);
        }
    };

    const setDuration = (selectedPackage: PackageName, duration: number | null) => {
        if (!duration) return;
        if (isNaN(duration) || !venueId) {
            return;
        }
        updateReservation({ duration });
        if (isUpdateReservation) {
            updateReservation({ slots: undefined });
            changeVenuePackageWithReservation({ selectDuration: duration });
        } else {
            changeVenuePackage({
                updatePackage: selectedPackage,
                venueId,
                date,
                guests,
                selectDuration: duration,
                guestsWithAgeGroups: getGuestsWithAgeGroups(selectedPackage)
            });
        }
    };
    const returnToParty = () => {
        toPartyWithVenue(date);
    };
    const returnToCustom = () => {
        toCustomTab()
    };


    const onChangeCurrentPackage = (updatePackage: PackageName) => {
        setReservationError("");

        if (updatePackage === currentPackage) {
            setCurrentPackage(undefined);
            return;
        }

        const totalGuests = getAgeGroupsMinGuests(updatePackage, guests);
        const guestsWithAgeGroups = getGuestsWithAgeGroups(updatePackage)

        if (updatePackage.enableDurationChoice) {
            const parsedDuration = getPackageDurations(updatePackage)[0];
            updateReservation({ duration: parsedDuration });
            if (isUpdateReservation) {
                updateReservation({ slots: undefined });
                changeVenuePackageWithReservation({
                    updatePackage,
                    selectDuration: parsedDuration,
                });
            } else {
                changeVenuePackage({
                    updatePackage,
                    venueId,
                    date,
                    guests: totalGuests,
                    selectDuration: parsedDuration,
                    guestsWithAgeGroups
                });
            }
        } else {
            if (isUpdateReservation) {
                updateReservation({ slots: undefined });
                changeVenuePackageWithReservation({ updatePackage });
            } else {
                changeVenuePackage({
                    updatePackage, venueId, date, guests: totalGuests,
                    guestsWithAgeGroups
                });
            }
        }
    };
    const { singlePackage, packagesTag } = parseHash(hashParams);


    let packages =
        (singlePackage
            ? activeVenue?.packages?.filter((item) => item.id === currentPackage?.id)
            : activeVenue?.packages) || [];


    const [selectedPackageTag, setSelectedPackageTag] = useState('All')

    const onClearFilter = () => {
        replace({ search: searchParams });
        changeVenuePackage({
            updatePackage: currentPackage,
            venueId,
            date,
            guests,
            guestsWithAgeGroups: getGuestsWithAgeGroups(currentPackage),

        });
    };
    const [selectedPackageId, setSelectedPackageId] = useState<string | null>(null);
    const handleToggleShowMoreInfo = (packageId: string) => {
        setSelectedPackageId((prevId) => (prevId === packageId ? null : packageId));
    };

    const images = getVenueImages(activeVenue);
    const filteredPackages = useMemo(() => {
        return (uiConfig?.hideAllPackageTab && activeVenue?.packageTags && activeVenue.packageTags.length > 0 && selectedPackageTag !== 'All')
            ? packages.filter(pack => {
                const tags = pack.tags.split(';').map(tag => tag.split(':')[0].trim());
                return tags.some(tag => tag === selectedPackageTag.trim());
            })
            : packages;
    }, [uiConfig, activeVenue, selectedPackageTag, packages, isUpdateReservation]);

    const isWithImages = images && images.length > 0;
    return (
        <>
            {(isLoadingVenues || isLoadingVenueOrDate || isLoadingAvailability) && (
                <div className="loading">
                    <Spinner size={SpinnerSize.large} />
                </div>
            )}
            <div
                className="make-package-reservation-container main-container main-container-v2"
                style={{ backgroundColor: bodyColor }}
                ref={top}
            >
                <Header handleBackButton={goBack} />
                <div className="makePackageReservation-inner-container main-content main-content-v2" style={isTabletScreen ? { paddingBottom: 0 } : {}}>
                    <div
                        className={`makePackageReservation-main-information-v2 main-content main-content-v2 ${isMobile ? "mobile" : ""
                            }`}
                        style={isTabletScreen ? { paddingBottom: 0 } : {}}
                    >
                        <div className={`makePackageReservation-reservation makePackageReservation-reservation-v2 ${isTabletScreen ? 'tablet' : ''}`}
                        >
                            {!isMobile ? <>
                                <div className="makePackageReservation-reservation-title-v2">
                                    {activeVenue?.packagesTitle}
                                </div>
                                {activeVenue?.packagesDescription && <HTMLRenderer
                                    html={activeVenue?.packagesDescription}
                                    className="makePackageReservation-reservation-text-v2"
                                />}</> : <Box position={'relative'} height={'290px'}>

                                {(isLoadingVenues || isLoadingVenueOrDate) ? (
                                    <Skeleton
                                        variant="rect"
                                        width={'100%'}
                                        height={'290px'}
                                        style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px', transform: 'none' }}
                                    />
                                ) : (
                                    <img
                                        className="makePackageReservation-reservation-logo-v2"
                                        src={activeVenue?.venueImage || defaultImage}
                                        alt="venueImage"
                                    />
                                )}


                                {isWithImages && <Button className="makePackageReservation-reservation-btn-view-all-v2 body-small" onClick={() => setIsGalleryOpen(true)}>
                                    View All ({images.length})
                                </Button>}
                            </Box>}

                            <Box className={`makePackageReservation-reservation-header ${isMobile ? "mobile" : ""
                                }`}>

                                <Tabs
                                    value={tabValue}
                                    indicatorColor="primary"
                                    textColor="inherit"
                                    onChange={handleChangeTabValue}
                                    variant="fullWidth"
                                    TabIndicatorProps={{
                                        style: {
                                            backgroundColor: "#212B36",
                                            height: '3px',
                                            bottom: '-1px'
                                        }
                                    }}
                                    style={{
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.10)'
                                    }}
                                >
                                    <Tab label={activeVenue?.packagesTabName || "Reservations"} className="makePackageReservation-tab" />
                                    <Tab label={activeVenue?.eventInquiryTabName || activeVenue?.eventInquiryTitle || "Contact Us"} className="makePackageReservation-tab" />
                                    {activeVenue?.customTabEnable && <Tab label={activeVenue?.customTabTabName || 'Custom Tab'} className="makePackageReservation-tab" />}
                                </Tabs>
                                <Box className={`makePackageReservation-reservation-header-inside ${isMobile ? 'mobile' : ''}`}>
                                    <>
                                        <SelectWithIcon label="Select Date" options={[formatDate(date, isMobile) || 'Loading the date...']} onFormClick={() => setIsCalendar(!isCalendar)} onClick={() => { }} icon={<CalendarIcon />} disabled={isVenueClosed} />
                                        {isCalendar ? (
                                            <Calendar
                                                date={date}
                                                selectDate={selectDate}
                                                availability={availability}
                                                isMiddleScreen={isMiddleScreen}
                                                isInsideContainer
                                            />
                                        ) : null}
                                    </>

                                </Box>

                            </Box>

                            {isVenueClosed ? <VenueClosed text={activeVenue?.closedMessage && (activeVenue?.closedMessage !== '' && activeVenue?.closedMessage !== '<p><br></p>') ? <HTMLRenderer html={activeVenue.closedMessage} /> : ''} /> :
                                <div className={`package-list-container ${isMobile ? 'mobile-padding' : ''}`}>
                                    <Typography className="package-list-title-v2">
                                        <div>
                                            {activeVenue?.choosePackageTitle || "Select a package"}
                                        </div>
                                        {singlePackage && (
                                            <button className="clear-button" onClick={onClearFilter}>
                                                clear filter ×
                                            </button>
                                        )}
                                    </Typography>
                                    {activeVenue?.packageTags &&
                                        !singlePackage && activeVenue?.packageTags.length !== 0 ? (
                                        <FilterButtons
                                            values={
                                                activeVenue
                                                    ? [
                                                        ...(uiConfig?.hideAllPackageTab ? [] : [{ value: 'All' }]),
                                                        ...activeVenue.packageTags.map((tag) => ({ value: tag })),
                                                    ]
                                                    : []
                                            }
                                            filterValue={selectedPackageTag as string}
                                            onFilterChange={(tag) => selectPackageTag(tag)}
                                            style={{ marginBottom: 0, marginLeft: isMobile ? '-24px' : '-15px', maxWidth: '820px' }}
                                        />
                                    ) : null}
                                    {(isLoadingPackageList) ?
                                        <PackageSkeletons mt={'32px'} /> :
                                        <Box display={'flex'} flexDirection={'column'} style={{ gap: '32px' }} mt={'32px'}>
                                            {filteredPackages
                                                .map((item) => (<PackageCard
                                                    key={item.id}
                                                    packageName={item}
                                                    onContactUs={returnToParty}
                                                    onChangeCurrentPackage={onChangeCurrentPackage}
                                                    setDuration={setDuration}
                                                    timeButtonsGroups={timeButtonsGroups}
                                                    slots={slots}
                                                    setErrorGuestCount={setErrorGuestCount}
                                                    isShowMoreInfo={selectedPackageId === item.id}
                                                    toggleShowMoreInfo={() => handleToggleShowMoreInfo(item.id)}
                                                    isLessTreeTimeSlots={isLessTreeTimeSlots}
                                                    error={errorGuestCount}
                                                />
                                                ))}
                                        </Box>}
                                    {(filteredPackages.length === 0 && !isLoadingPackageList && !(isLoadingVenue || isLoadingVenues)) && <NoAvailiableGuests isHtml noSpace text={activeVenue?.inactivePackageMessage || `There are currently no available reservations for this
                                date. We still welcome walk-in groups for our bays on
                                a first come, first serve basis.`} />}
                                </div>
                            }
                            <>


                                {isUpdateReservation && (
                                    <InfoAlert
                                        className="info-alert"
                                        text="You are modifying an existing reservation. Based on your new selection there may be a price difference"
                                    />
                                )}
                                {(requestError) && (
                                    <div className="error">
                                        {requestError}
                                    </div>
                                )}

                            </>

                            {isMobile && <PlanningEvent activeVenue={activeVenue} />}
                            <AboutVenue
                                mt={"32px"}
                                p={"32px"}
                                description={activeVenue?.description as string}
                                title={activeVenue?.descriptionTitle || ""}
                            />
                            {activeVenue?.faqs ? (
                                <ThingsToKnow
                                    mt={"32px"}
                                    accordionData={[
                                        ...convertFaqsToArray(activeVenue.faqs),
                                        ...venuePolicies,
                                    ]}
                                    title={activeVenue.faqTitle || "Things to Know"}
                                />
                            ) : null}
                            {isTabletScreen && <PlanningEvent
                                mt={'32px'}
                                maxWidth={'100% !important'}
                                descriptionProps={{
                                    style: {
                                        maxWidth: '100%'
                                    }
                                }}
                                activeVenue={activeVenue} />}
                        </div>
                        {!isMobile && <MakeReservationSummary venue={activeVenue} venueNames={venues} selectVenueOption={selectVenueOption} />}
                    </div>
                </div>

                <Footer className={isMobile ? "mobile-footer" : ""} />
            </div >
            <GalleryModal onOpen={() => setIsGalleryOpen(true)} venue={activeVenue} onClose={() => setIsGalleryOpen(false)} open={isGalleryOpen} />
        </>
    );
};

export default connector(MakePackageReservation);
