import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { State } from "../store/types";

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  State,
  unknown,
  Action<string>
>;

export enum ActionTypeEnum {
  // auth
  AuthRequest = "auth/AUTH_REQUEST",
  AuthSuccess = "auth/AUTH_SUCCESS",
  AuthSetClient = "auth/AUTH_SET_CLIENT",
  AuthtorizeSession = "auth/AUTH_SESSION",
  AuthFailure = "auth/AUTH_FAILURE",
  AuthRecoverRequest = "auth/AUTH_RECOVER_REQUEST",
  AuthRecoverSuccess = "auth/AUTH_RECOVER_SUCCESS",
  AuthRecoverFailure = "auth/AUTH_RECOVER_FAILURE",
  AuthResetRecoverStatus = "auth/AUTH_RESET_RECOVER_STATUS",
  ReservationAuthRequest = "auth/RESERVATION_AUTH_REQUEST",
  ReservationAuthSuccess = "auth/RESERVATION_AUTH_SUCCESS",
  ReservationAuthFailure = "auth/RESERVATION_AUTH_FAILURE",
  Logout = "auth/LOGOUT",
  GetClient = "auth/GET_CLIENT",
  ChangePasswordRequest = "auth/CHANGE_PASSWORD_REQUEST",
  ChangePasswordSuccess = "auth/CHANGE_PASSWORD_SUCCESS",
  ChangePasswordFailure = "auth/CHANGE_PASSWORD_FAILURE",
  ResetPasswordRequest = "auth/RESET_PASSWORD_REQUEST",
  ResetPasswordSuccess = "auth/RESET_PASSWORD_SUCCESS",
  ResetPasswordFailure = "auth/RESET_PASSWORD_FAILURE",
  UpdateProfileRequest = "auth/UPDATE_PROFILE_REQUEST",
  UpdateProfileSuccess = "auth/UPDATE_PROFILE_SUCCESS",
  UpdateProfileFailure = "auth/UPDATE_PROFILE_FAILURE",
  GetGuest = "auth/GET_GUEST",
  GetGuestSuccess = "auth/GET_GUEST_SUCCESS",
  GetGuestFailure = "auth/GET_GUEST_FAILURE",
  LogoutAsGuest = "auth/LOGOUT_AS_GUEST",
  CleanAuthError = "auth/CLEAN_AUTH_ERROR",
  SetIsShowVerificationCodeModal = "auth/SET_IS_SHOW_VERIFICATION_CODE_MODAL",
  GetVerificationCode = "auth/GET_VERIFICATION_CODE",
  GetVerificationCodeSuccess = "auth/GET_VERIFICATION_CODE_SUCCESS",
  GetVerificationCodeFailure = "auth/GET_VERIFICATION_CODE_FAILURE",
  ConfirmVerification = "auth/CONFIRM_VERIFICATION",
  ConfirmVerificationSuccess = "auth/CONFIRM_VERIFICATION_SUCCESS",
  ConfirmVerificationFailure = "auth/CONFIRM_VERIFICATION_FAILURE",

  //venues
  GetVenues = "venue/GET_VENUES",
  GetVenuesSuccess = "venue/GET_VENUES_SUCCESS",
  GetVenuesFailure = "venue/GET_VENUES_FAILURE",
  GetVenue = "venue/GET_VENUE",
  GetVenueSuccess = "venue/GET_VENUE_SUCCESS",
  GetVenueFailure = "venue/GET_VENUE_FAILURE",
  GetVenueWithAvailability = "venue/GET_VENUE_WITH_AVAILABILITY",
  GetVenueWithAvailabilitySuccess = "venue/GET_VENUE_WITH_AVAILABILITY_SUCCESS",
  GetVenueWithAvailabilityFailure = "venue/GET_VENUE_WITH_AVAILABILITY_FAILURE",
  SetCurrentPackage = "venue/SET_CURRENT_PACKAGE",

  //Reservation
  ResetReservation = "reservation/RESET_RESERVATION",
  UpdateReservation = "reservation/UPDATE_RESERVATION",
  StartBooking = "reservation/START_BOOKING",
  StartBookingRequest = "reservation/START_BOOKING_REQUEST",
  StartBookingFailure = "reservation/START_BOOKING_FAILURE",
  StopBooking = "reservation/STOP_BOOKING",
  ConfirmReservation = "reservation/CONFIRM_RESERVATION",
  CompletePayment = "reservation/COMPLETE_PAYMENT",
  GetReservationsRequest = "reservation/GET_RESERVATIONS_REQUEST",
  GetReservationsSuccess = "reservation/GET_RESERVATIONS_SUCCESS",
  GetReservationsFailure = "reservation/GET_RESERVATIONS_FAILURE",
  GetReservationCancellationRequest = "reservation/GET_RESERVATION_CANCELLATION_REQUEST",
  GetReservationCancellationSuccess = "reservation/GET_RESERVATION_CANCELLATION_SUCCESS",
  GetReservationCancellationFailure = "reservation/GET_RESERVATION_CANCELLATION_FAILURE",
  UpdateConfirmation = "reservation/UPDATE_CONFIRMATION",
  HideCancellation = "reservation/HIDE_CANCELLATION",
  SetUpdatingReservation = "reservation/SET_UPDATING_RESERVATION",
  SetUpdatingWithVenueChange = "reservation/SER_UPDATING_WITH_VENUE_CHANGE",
  HideModification = "reservation/HIDE_MODIFICATION",
  ClearReservationModification = "reservation/CLEAR_RESERVATION_MODIFICATION",
  GetReservationModificationRequest = "reservation/GET_RESERVATION_MODIFICATION_REQUEST",
  GetReservationModificationSuccess = "reservation/GET_RESERVATION_MODIFICATION_SUCCESS",
  GetReservationModificationFailure = "reservation/GET_RESERVATION_MODIFICATION_FAILURE",
  SetReservationAddons = "reservation/SET_RESERVATION_ADDONS",
  SetFortisClientToken = "reservation/SET_FORTIS_CLIENT_TOKEN",
  GoTabInformationRequest = "reservation/GO_TAB_INFORMATION",
  GoTabInformationRequestSuccess = "reservation/GO_TAB_INFORMATION_SUCCESS",
  GoTabInformationRequestFailure = "reservation/GO_TAB_INFORMATION_FAILURE",
  GoTabPaymentStatus = "reservation/GO_TAB_PAYMENT_STATUS",
  GoTabPaymentStatusSuccess = "reservation/GO_TAB_PAYMENT_STATUS_SUCCESS",
  GoTabPaymentStatusFailure = "reservation/GO_TAB_PAYMENT_STATUS_FAILURE",
  GetStripeListOfSavedCardsRequest = "reservation/GET_STRIPE_LIST_OF_SAVED_CARDS_REQUEST",
  GetStripeListOfSavedCardsSuccess = "reservation/GET_STRIPE_LIST_OF_SAVED_CARDS_SUCCESS",
  GetStripeListOfSavedCardsFailure = "reservation/GET_STRIPE_LIST_OF_SAVED_CARDS_FAILURE",

  //Addons
  SetAddonQuantities = "addons/SET_ADDON_QUANTITIES",
  SetActiveAddons = "addons/SET_ACTIVE_ADDONS",
  SetAddonsError = "addons/SET_ADDONS_ERROR",

  SubmitPartyRequest = "reservation/SUBMIT_PARTY",
  SubmitPartySuccess = "reservation/SUBMIT_SUCCESS",
  SubmitPartyFailure = "reservation/SUBMIT_FAILURE",
  SetIsPartySubmitted = "reservation/SET_PARTY_SUBMITTED",

  PaymentRequest = "reservation/PAYMENT_REQUEST",
  PaymentSuccess = "reservation/PAYMENT_SUCCESS",
  PaymentFailure = "reservation/PAYMENT_FAILURE",

  SetReservationError = "reservation/SET_ERROR",

  CancelReservation = "reservation/CANCEL_RESERVATION",
  CancelReservationSuccess = "reservation/CANCEL_RESERVATION_SUCCESS",
  CancelReservationFailure = "reservation/CANCEL_RESERVATION_FAILURE",

  LoadReservation = "reservation/LOAD_RESERVATION",
  LoadReservationSuccess = "reservation/LOAD_RESERVATION_SUCCESS",
  LoadReservationFailure = "reservation/LOAD_RESERVATION_FAILURE",

  RePayRequest = "reservation/RE_PAY",
  RePayRequestSuccess = "reservation/RE_PAY_SUCCESS",
  RePayRequestFailure = "reservation/RE_PAY_FAILURE",

  GetGiftCardBalance = "reservation/GET_GIFT_CARD_BALANCE",
  GetGiftCardBalanceSuccess = "reservation/GET_GIFT_CARD_BALANCE_SUCCESS",
  GetGiftCardBalanceFailure = "reservation/GET_GIFT_CARD_BALANCE_FAILURE",

  addGiftCardPayment = "reservation/ADD_GIFT_CARD_PAYMENT",

  ApplyCoupons = "reservation/APPLY_COUPONS",
  ApplyCouponsSuccess = "reservation/APPLY_COUPONS_REQUEST",
  ApplyCouponsFailure = "reservation/APPLY_COUPONS_FAILURE",

  //Square
  GetSquareAppId = "venue/GET_SQUARE_APP_ID",
  GetSquareAppIdSuccess = "venue/GET_SQUARE_APP_ID_SUCCESS",
  GetSquareAppIdFailure = "venue/GET_SQUARE_APP_ID_FAILURE",
  CheckSquareOrderCalculation = "reservation/CHECK_SQUARE_ORDER_CALCULATION",
  CheckSquareOrderCalculationSuccess = "reservation/CHECK_SQUARE_ORDER_CALCULATION_SUCCESS",
  CheckSquareOrderCalculationFailure = "reservation/CHECK_SQUARE_ORDER_CALCULATION_FAILURE",
  CreateSquarePartial = "reservation/CREATE_SQUARE_PARTIAL",
  CreateSquarePartialSuccess = "reservation/CREATE_SQUARE_PARTIAL_SUCCESS",
  CreateSquarePartialFailure = "reservation/CREATE_SQUARE_PARTIAL_FAILURE",

  //ui
  SetInfoState = "ui/SET_INFO_STATE",

  GetUIConfig = 'ui/GET_UI_CONFIG',
  GetUIConfigSuccess = 'ui/GET_UI_CONFIG_SUCCESS',
  GetUIConfigFailure = 'ui/GET_UI_CONFIG_FAILURE',



  //Membership
  SelectPlan = 'membership/SELECT_PLANS',
  GetPlans = 'membership/GET_PLANS',
  GetPlansSuccess = 'membership/GET_PLANS_SUCCESS',
  GetPlansFailure = 'membership/GET_PLANS_FAILURE',
  SubscribeToPlan = 'membership/SUBSCRIBE_TO_PLAN',
  SubscribeToPlanSuccess = 'membership/SUBSCRIBE_TO_PLAN_SUCCESS',
  SubscribeToPlanFailure = 'membership/SUBSCRIBE_TO_PLAN_FAILURE',
  UnSubscribeToPlan = 'membership/UNSUBSCRIBE_TO_PLAN',
  UnSubscribeToPlanSuccess = 'membership/UNSUBSCRIBE_TO_PLAN_SUCCESS',
  UnSubscribeToPlanFailure = 'membership/UNSUBSCRIBE_TO_PLAN_FAILURE',
  GetBillingSession = 'membership/GET_BILLING_SESSION',
  GetBillingSessionSuccess = 'membership/GET_BILLING_SESSION_SUCCESS',
  GetBillingSessionFailure = 'membership/GET_BILLING_SESSION_FAILURE',
  ClearAuthError = 'membership/CLEAR_AUTH_ERROR',
  SetSelectedPlan = 'membership/SET_SELECTED_PLAN',
  SetBilling = 'membership/SET_BILLING',
  SetSinglePlan = 'membership/SET_SINGLE_PLAN',

  // Activities
  GetActivities = 'activities/GET_ACTIVITIES',
  GetActivitiesSuccess = 'activities/GET_ACTIVITIES_SUCCESS',
  GetActivitiesFailure = 'activities/GET_ACTIVITIES_FAILURE',
  SetCurrentActivity = 'activities/SET_CURRENT_ACTIVITY',
  UpdateActivity = 'activities/UPDATE_ACTIVITY',
  GetActivity = 'activities/GET_ACTIVITY',
  GetActivitySuccess = 'activities/GET_ACTIVITY_SUCCESS',
  GetActivityFailure = 'activities/GET_ACTIVITY_FAILURE',

}
