import { ThunkDispatch } from "redux-thunk";
import { selectAuthenticated, selectGuest } from "../reducers/auth-reducer";
import { selectCurrentPackage, selectVenue } from "../reducers/venues";
import { State } from "../store/types";
import { getCurrentPackageActivitiesAction, pushUrlPathAction } from "../actions/reservation-actions";
import { AnyAction } from "redux";

export enum BookingStep {
    Selection = 'selection',
    Activities = 'activities',
    Addons = 'addons',
    ReservationConfirm = 'reservation-confirm',
    Account = 'account',
}

const steps = [
    {
        name: BookingStep.Selection,
        condition: (state: State) => {
            const venue = selectVenue(state);
            return !!venue;
        },
        action: (dispatch: ThunkDispatch<State, void, AnyAction>, state: State) => {
            const venue = selectVenue(state);
            dispatch(
                pushUrlPathAction('/package', {
                    venue: venue?.name ?? '',
                })
            );
        },
    },
    {
        name: BookingStep.Activities,
        condition: (state: State) => {
            const currentPackage = selectCurrentPackage(state);
            return currentPackage?.hasActivities
        },
        action: (dispatch: ThunkDispatch<State, void, AnyAction>, state: State) => {
            const venue = selectVenue(state);
            dispatch(
                pushUrlPathAction('/reservation-info/activities', {
                    venue: venue?.name,
                })
            );
            const currentPackage = selectCurrentPackage(state);
            if (currentPackage?.id) {
                dispatch(getCurrentPackageActivitiesAction(currentPackage.id));
            }
        },
    },
    {
        name: BookingStep.Addons,
        condition: (state: State) => {
            const venue = selectVenue(state);
            return (venue?.addons || []).length > 0;
        },
        action: (dispatch: ThunkDispatch<State, void, AnyAction>, state: State) => {
            const venue = selectVenue(state);
            dispatch(
                pushUrlPathAction('/reservation-info/addons', {
                    venue: venue?.name,
                })
            );
        },
    },
    {
        name: BookingStep.ReservationConfirm,
        condition: (state: State) => {
            const authenticated = selectAuthenticated(state) || !!selectGuest(state);
            return authenticated;
        },
        action: (dispatch: ThunkDispatch<State, void, AnyAction>, state: State) => {
            const venue = selectVenue(state);
            dispatch(
                pushUrlPathAction('/reservation-info/reservation-confirm', {
                    venue: venue?.name,
                })
            );
        },
    },
    {
        name: BookingStep.Account,
        condition: (state: State) => {
            const authenticated = selectAuthenticated(state) || !!selectGuest(state);
            return !authenticated;
        },
        action: (dispatch: ThunkDispatch<State, void, AnyAction>, state: State) => {
            const venue = selectVenue(state);
            dispatch(
                pushUrlPathAction('/reservation-info/account', {
                    continue: true,
                    venue: venue?.name,
                })
            );
        },
    },
];


export const determineNextStep = (
    dispatch: any,
    getState: () => State,
    currentStep: BookingStep
) => {
    const state = getState();
    const currentIndex = steps.findIndex((step) => step.name === currentStep);
    if (currentIndex === -1) {
        console.error(`Invalid step: ${currentStep}`);
        return
    }
    for (let i = currentIndex + 1; i < steps.length; i++) {
        const step = steps[i];
        if (step.condition(state)) {
            step.action(dispatch, state);
            return
        }
    }

}