import { PackageName, Reservation, STEP } from "../store/types";

export interface StepConfig {
    label: STEP;
    name: string;
    condition?: (props: StepConfigProps) => boolean;
    handler: (props: StepConfigProps) => () => void;
}

export interface StepConfigProps {
    diffStep: number;
    currentPackage?: PackageName;
    pushUrlPath: (path: string, params?: any) => void;
    backToReservation: () => void;
    reservation: Reservation;
}

export const reservationSteps: StepConfig[] = [
    {
        label: STEP.INIT,
        name: "Selection",
        handler: ({ pushUrlPath }) => () => pushUrlPath("/reservation"),
    },
    {
        label: STEP.ACTIVITIES,
        name: "Activities",
        condition: ({ currentPackage }) => currentPackage?.hasActivities || false,
        handler: ({ pushUrlPath }) => () => pushUrlPath("/reservation-info/activities"),
    },
    {
        label: STEP.ADDONS,
        name: "Add Ons",
        condition: ({ diffStep }) => diffStep === 0,
        handler: ({ pushUrlPath, reservation }) => () =>
            pushUrlPath("/reservation-info/addons", { venue: reservation.venueName }),
    },
    {
        label: STEP.ACCOUNT,
        name: "Account",
        handler: ({ pushUrlPath }) => () => pushUrlPath("/account"),
    },
    {
        label: STEP.RESERVATION,
        name: "Details",
        handler: ({ pushUrlPath, reservation }) => () =>
            pushUrlPath("/reservation-info/reservation-confirm", { venue: reservation.venueName }),
    },
    {
        label: STEP.PAYMENT,
        name: "Payment",
        handler: ({ pushUrlPath }) => () => pushUrlPath("/reservation-info/payment"),
    },
    {
        label: STEP.CONFIRMATION,
        name: "Confirmation",
        handler: ({ pushUrlPath }) => () => pushUrlPath("/reservation-info/confirmed"),
    },
];

