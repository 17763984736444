import { UIConfigDto } from "../../../../server/src/dto/settings.dto";

export * from "../../../../server/src/dto/settings.dto";

export enum STEP {
  INIT = 1,
  ACTIVITIES = 2,
  ADDONS = 3,
  ACCOUNT = 4,
  RESERVATION = 5,
  PAYMENT = 6,
  CONFIRMATION = 7
}

export interface UIState {
  uiConfig?: UIConfigDto;
  bootstrapped: boolean;
  infoState: STEP;
}
