import _ from "lodash";
import { PackageName, ProfileDto } from "../store/types";

export const getIsMemberPlanAvailableForPackage = ({
    packageName,
    client,
}: {
    packageName: PackageName;
    client?: ProfileDto;
}) => {
    if (!packageName.membershipPlanAssignedIds || packageName.membershipPlanAssignedIds.length === 0) return true;

    const clientActivePlanIds = client?.subscriptions
        ?.filter(sub => sub.active)
        ?.map(sub => sub.plan.id) || [];

    const hasMatchingPlan = _.intersection(clientActivePlanIds, packageName.membershipPlanAssignedIds).length > 0;

    return hasMatchingPlan;
};