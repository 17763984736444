import { GuestsWithAgeGroup } from "./guestsWithAgeGroup";

export class ReservationActivityDto {
    slots: number;
    duration: number;
    guests: number;
    lanes: number;
    price: number;
    tax: number;
    serviceFee: number;
    startTime: number;
    packageId: string;
    packageName: string;
    preBufferTime: number;
    bufferTime: number;
    resources?: ReservationActivityResourceDto[]
}

export class ReservationActivityResourceDto {
    id: string;
    resourceId: string;
    resourceAbbreviation: string;
    name: string;
    categoryId: string;
    categoryName: string;
    reservationActivityId: string;
    steltronicNumber: number;
    atomicGolfBayId: string;
    tripleSeatRoomId: number;
    aboutGolfSimId: string;
}


export class CreateReservationActivity {
    slots: number;
    duration: number;
    guests: number;
    guestsWithAgeGroups?: GuestsWithAgeGroup[];
    lanes: number;
    packageId: string;
    resources: ReservationActivityResourceDto[];
    startTime: number;
}