import { useMemo } from 'react';
import { filter, map, memoize } from 'lodash';
import { reservationSteps, StepConfigProps, StepConfig } from '../utils/reservationSteps';

export const useReservationSteps = (props: StepConfigProps) => {
    return useMemo(() => {
        const filteredSteps = filter(reservationSteps, (step: StepConfig) => {
            return !step.condition || step.condition(props);
        });

        const processedSteps = map(filteredSteps, (step: StepConfig) => {
            const adjustedLabel = step.label - (props.diffStep || 0);
            const stepHandler = step.handler(props);

            return {
                ...step,
                label: adjustedLabel,
                handler: stepHandler,
            };
        });

        return processedSteps;
    }, [props]);
}

